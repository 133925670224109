import {Component, Input, OnInit} from '@angular/core';
import {Provider} from "../../Models/Provider";

@Component({
  selector: 'vp-eligibility-benefits-receiver',
  templateUrl: './eligibility-benefits-receiver.component.html',
  styleUrls: ['./eligibility-benefits-receiver.component.css']
})
export class EligibilityBenefitsReceiverComponent implements OnInit {

  constructor() { }

  @Input("receiver") data!: Provider

  ;
  ngOnInit(): void {
  }

}
