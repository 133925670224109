import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {ReimbursementHistoryComponent} from "./reimbursement-history-component/reimbursement-history.component";
import {AuthGuardWithForcedLogin} from "../core/auth-guard-with-forced-login.service";


@NgModule({
    declarations: [
        ReimbursementHistoryComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            {
                path: 'reimbursementHistory/:carrierId/:npi/:policyNumber',
                component: ReimbursementHistoryComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },
            {
                path: 'reimbursementHistory/:carrierId/:npi',
                component: ReimbursementHistoryComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },
            {path: 'reimbursementHistory', component: ReimbursementHistoryComponent, canActivate: [AuthGuardWithForcedLogin]}

        ])
    ]
})
export class ReimbursementModule {
}
