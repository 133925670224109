import {Component, Input, OnInit} from '@angular/core';
import {EntityName} from "../../Models/EntityName";


@Component({
  selector: 'vp-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.css']
})
export class NameComponent implements OnInit {

  constructor() { }

  @Input() entityName!  : EntityName;


  ngOnInit(): void {
  }

}
