<div>
    <div class="card" *ngIf="errorMessage">
        <div class="card-header">
            Alert
        </div>
        <div class="card-body">
            <div class="row">
                <div>
                    <div class="row">
                        <div class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="data" class="mat-elevation-z8">
        <div class="card">
            <div class="card-header">
                Summary
            </div>
            <div class="card-body">


                <div class="row">
                    Id: {{data.id}}
                </div>

                <div class="row">
                    NPI: {{data.npi}}
                </div>

                <div class="row">
                    Prospect: {{data.prospectId}}
                </div>

                <div class="row">
                    IsEligible: {{data.isEligible}}
                </div>

                <div class="row">
                    IsError:  {{data.isError}}
                </div>

                <div class="row">
                    Payer: {{data.payer}}
                </div>


                <div *ngIf="data" class="mat-elevation-z8">
                    <div class="card">
                        <div class="card-header">
                            Request Data
                        </div>
                        <div class="card-body">

                            <div class="row" [innerHtml]="unsafe_request"></div>
                        </div>
                    </div>
                </div>

                <div *ngIf="data" class="mat-elevation-z8">
                    <div class="card">
                        <div class="card-header">
                 ;           Response Data
                        </div>
                        <div class="card-body">

                            <div class="row" [innerHtml]="unsafe_response"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>