import {Component, Input, OnInit} from '@angular/core';
import {QualifiedDateRange} from "../../Models/QualifiedDateRange";

@Component({
  selector: 'vp-qualified-date-range',
  templateUrl: './qualified-date-range.component.html',
  styleUrls: ['./qualified-date-range.component.css']
})
export class QualifiedDateRangeComponent implements OnInit {

  constructor() { }

  @Input ("qualifiedDateRange") data!: QualifiedDateRange;

  ngOnInit(): void {
  }

}
