import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {TransactionSummaryDashboardComponent} from "./transaction-summary-dashboard.component";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";

@NgModule({
    declarations: [
        TransactionSummaryDashboardComponent
    ],
    exports: [
        TransactionSummaryDashboardComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild([]),
        MatTableModule,
        MatSortModule
    ]
})
export class TransactionSummaryDashboardModule {
}
