import {Component, Input, OnInit} from '@angular/core';
import {RequestValidation} from "../../Models/RequestValidation";

@Component({
  selector: 'vp-request-validation',
  templateUrl: './request-validation.component.html',
  styleUrls: ['./request-validation.component.css']
})
export class RequestValidationComponent implements OnInit {

  constructor() { }

  @Input ("requestValidation") data! : RequestValidation

  ngOnInit(): void {
  }

}
