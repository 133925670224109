import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {WelcomeComponent} from "./home/welcome.component";
import {ReimbursementModule} from "./reimbursement/reimbursement.module";
import {LogResponseIntercepterService} from "./shared/log-response-intercepter.service";
import {AppErrorService} from "./shared/app-error.service";
import {BASE_URL} from "./app.tokens";
import {DatePipe} from "@angular/common";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TransactionSummaryDashboardModule} from "./transaction-summary-dashboard/transaction-summary-dashboard.module";
import {DailyTransactionModule} from "./experian-transactions/daily-transaction.module";
import {EligibilityModule} from "./eligibility/eligibility.module";
import {CoreModule} from "./core/core.module";
import { FallbackComponent } from './fallback.component';
import { ShouldLoginComponent } from './should-login.component';
import { AppMenuComponent } from './app-menu.component';
import {AuthGuardWithForcedLogin} from "./core/auth-guard-with-forced-login.service";
import { ExperianPayersUploadModule } from './core/experian-payers-upload/experian-payers-upload.module';

@NgModule({
    declarations: [
        AppComponent,
        AppMenuComponent,
        WelcomeComponent,
        FallbackComponent,
        ShouldLoginComponent,

    ],
    imports: [
        ExperianPayersUploadModule,
        BrowserModule,
        HttpClientModule,
        EligibilityModule,
        CoreModule.forRoot(),
        ReimbursementModule,
        RouterModule.forRoot([
            {
                path: 'welcome',
                component: WelcomeComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },
            {
                path: '',
                redirectTo: 'welcome',
                pathMatch: 'full'
            },
            {
                path: 'should-login',
                component: ShouldLoginComponent
            },
            {
                path: '**',
                component: FallbackComponent
            },
        ]),
        BrowserAnimationsModule,
        TransactionSummaryDashboardModule,
        DailyTransactionModule,
    ],
    providers: [
        DatePipe,
        {provide: BASE_URL, useValue: '${window.location.origin}' },
        {provide: ErrorHandler, useClass: AppErrorService},
        {provide: HTTP_INTERCEPTORS, useClass: LogResponseIntercepterService, multi: true}
    ],
    bootstrap: [AppComponent],

    exports: [

    ]
})
export class AppModule {
}
