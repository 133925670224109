import {Component, Input, OnInit} from '@angular/core';
import {EntityType} from "../../Models/EntityType";

@Component({
  selector: 'vp-entity-type',
  templateUrl: './entity-type.component.html',
  styleUrls: ['./entity-type.component.css']
})
export class EntityTypeComponent implements OnInit {

  constructor() { }

  @Input("entityType") data! : EntityType

  ngOnInit(): void {
  }

}
