<div>
    <div class="card">
        <div class="card-header">
            {{pageTitle}}
        </div>

    </div>

    <div class="card" *ngIf="errorMessage">
        <div class="card-header">
            Alert
        </div>
        <div class="card-body">
            <div class="row">
                <div>
                    <div class="row">
                        <div class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="reimbursement">

        <div class="card">
            <div class="card-header">
                Payer History
            </div>
            <div class="card-body">
                <div class="row">
                    <div>
                        <div class="row">
                            <div class="col-md-3">Reimbursement Percent:</div>
                            <div class="col-md-6">{{reimbursement.payerHistory.reimbursementPercent |json}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">confidenceIndex:</div>
                            <div class="col-md-6">{{reimbursement.payerHistory.confidenceIndex}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                Facility
            </div>
            <div class="card-body">
                <div class="row">
                    <div>
                        <div class="row">
                            <div class="col-md-3">NPI:</div>
                            <div class="col-md-6">{{reimbursement.facility.npi}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Network:</div>
                            <div class="col-md-6">{{reimbursement.facility.network}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                Black List
            </div>
            <div class="card-body">
                <table class="table table-striped col-md-12">
                    <thead>
                    <tr>
                        <th scope="col">Employer Name</th>
                        <th scope="col">Carrier Name</th>
                        <th scope="col">Payer Name</th>
                        <th scope="col">Policy Prefix</th>
                        <th scope="col">Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let blacklistEntity of reimbursement.blackList">
                        <td>{{blacklistEntity.employerName}}</td>
                        <td>{{blacklistEntity.carrierName}}</td>
                        <td>{{blacklistEntity.payerName}}</td>
                        <td>{{blacklistEntity.policyPrefix}}</td>
                        <td>{{blacklistEntity.blackListType}}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>
