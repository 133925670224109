import {Component, OnInit} from '@angular/core';
import {HistoricalEligibilitySummary} from "../../Models/HistoricalEligibilitySummary";
import {Subscription} from "rxjs";
import {HttpUrlEncodingCodec} from "@angular/common/http";
import {DataService} from "../../shared/data.service";
import {ActivatedRoute} from "@angular/router";
import {AppError} from "../../Models/AppError";
import {MatTableDataSource} from "@angular/material/table";
import {HistoricalEligibilitySummaryRecord} from "../../Models/HistoricalEligibilitySummaryRecord";


@Component({
    selector: 'app-eligibility-summary-historical',
    templateUrl: './eligibility-summary-historical.component.html',
    styleUrls: ['./eligibility-summary-historical.component.css']
})

export class EligibilitySummaryHistoricalComponent implements OnInit {

    pageTitle = 'Historical Experian Summary';
    errorMessage = '';
    searchId!: string;
    displayColumn: string[] = ['eligibilityOrBenefitCode', 'coverageLevel', 'services', 'period', 'amount', 'percentAsDecimal', 'messages'];
    overallDatasource!: MatTableDataSource<HistoricalEligibilitySummaryRecord>;
    inNetworkDatasource!: MatTableDataSource<HistoricalEligibilitySummaryRecord>;
    outOfNetworkDatasource!: MatTableDataSource<HistoricalEligibilitySummaryRecord>;

    data: HistoricalEligibilitySummary | undefined;

    HttpUrlEncodingCodec = new HttpUrlEncodingCodec();


    private dataSub!: Subscription;

    constructor(private dataService: DataService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {

        let id = this.route.snapshot.paramMap.get('id');
        if (id != null) {
            this.searchId = id;
            this.fetchData(this.searchId);
        }
    };

    search(event: Event) {
        if (event.target) {
            this.searchId = (event.target as HTMLInputElement)?.value;
            this.fetchData(this.searchId);
        }
    };

    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }

    private fetchData(id: string): void {
        this.errorMessage ='';
        this.data = undefined; //new HistoricalEligibilitySummary;
        this.overallDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>();
        this.inNetworkDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>();
        this.outOfNetworkDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>();

        this.dataSub = this.dataService.getHistoricalEligibilitySummary(this.HttpUrlEncodingCodec.decodeValue(id))
            .subscribe({
                next: (responseData: HistoricalEligibilitySummary | AppError) => {
                    this.data = <HistoricalEligibilitySummary>responseData;
                    this.overallDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>(this.data?.overall);
                    this.inNetworkDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>(this.data?.inNetWork);
                    this.outOfNetworkDatasource = new MatTableDataSource<HistoricalEligibilitySummaryRecord>(this.data?.outOfNetwork);
                },
                error: (err: AppError) => {
                    console.error('HistoricalEligibilitySummaryComponent Observer got an error: ' + JSON.stringify(err));
                    this.errorMessage = err.friendlyMessage;
                },
                complete: () => {
                    console.log('EligibilitySummaryComponent Observer got a complete notification');
                }
            })
    };


}
