import {Component, Input, OnInit} from '@angular/core';
import {ExperianResponseValidation} from "../../Models/ExperianResponseValidation";

@Component({
  selector: 'vp-response-validation',
  templateUrl: './response-validation.component.html',
  styleUrls: ['./response-validation.component.css']
})
export class ResponseValidationComponent implements OnInit {

  constructor() { }

  @Input("experianResponseValidation") data! : ExperianResponseValidation;

  ngOnInit(): void {
  }

}
