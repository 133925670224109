import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AppError } from "../Models/AppError";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable({
  providedIn: "root",
})
export class AppErrorService implements ErrorHandler {
  constructor(private oauthService: OAuthService) {}

  //For now just log errro to console and build a user frendly error message
  handleError(err: HttpErrorResponse): Observable<AppError> {
    let appError = new AppError();
    appError.message =
      "status text: " + err.statusText + "   Message: " + err.message;

    console.error(err);
    if (err.status === 401) {
      this.oauthService.restartSessionChecksIfStillLoggedIn();
    }

    appError.errorNumber = err.status;
    if (err.error.detail) {
      appError.apiErrorMessage = err.error.detail;
    } else {
      appError.apiErrorMessage = err.error;
    }

    return throwError(() => appError);
  }
}
