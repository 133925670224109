import {Component, Input, OnInit} from '@angular/core';
import {PostalAddress} from "../../Models/PostalAddress";

@Component({
  selector: 'vp-postal-address',
  templateUrl: './postal-address.component.html',
  styleUrls: ['./postal-address.component.css']
})
export class PostalAddressComponent implements OnInit {

  constructor() { }
@Input ("postalAddress") data! : PostalAddress

  ngOnInit(): void {
  }

}
