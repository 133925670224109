

<div *ngIf="data">
    <div class="card">
        <div class="card-header">
            Eligibility Query
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">Carrier Id</div>
                <div class="col-md-6">{{data.carrierId}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">State</div>
                <div class="col-md-6">{{data.state}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Prospect ID</div>
                <div class="col-md-6">{{data.prospectId}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Facility</div>
                <div class="col-md-6">{{data.facilityId}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Payer Name</div>
                <div class="col-md-6">{{data.payerName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Responses</div>
                <div class="col-md-6">{{data.responses}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Service Type Code</div>
                <div class="col-md-6">{{data.serviceTypeCode}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient Facility Identifier</div>
                <div class="col-md-6">{{data.patientFacilityIdentifier}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Health Plan Identifier</div>
                <div class="col-md-6">{{data.healthPlanIdentifier}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient Identifier</div>
                <div class="col-md-6">{{data.patientIdentifier}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient Last Name</div>
                <div class="col-md-6">{{data.patientLastName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient First Name</div>
                <div class="col-md-6">{{data.patientFirstName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient Date Of Birth</div>
                <div class="col-md-6">{{data.patientDateOfBirth}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Patient Gender</div>
                <div class="col-md-6">{{data.patientGender}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Policy Holder Last Name</div>
                <div class="col-md-6">{{data.policyHolderLastName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Policy First Name</div>
                <div class="col-md-6">{{data.policyHolderFirstName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Policy Holder Date Of Birth</div>
                <div class="col-md-6">{{data.policyHolderDateOfBirth}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Policy Holder Identifier</div>
                <div class="col-md-6">{{data.policyHolderIdentifier}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Eligibility Begin Date</div>
                <div class="col-md-6">{{data.eligibilityBeginDate}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Eligibility End Date</div>
                <div class="col-md-6">{{data.eligibilityEndDate}}</div>
            </div>
        </div>
    </div>
</div>
