import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ExperianPayersUploadComponent} from "./experian-payers-upload.component";
import {AuthGuardWithForcedLogin} from "src/app/core/auth-guard-with-forced-login.service";
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
    declarations: [
        ExperianPayersUploadComponent
    ],
    imports: [
        MatProgressBarModule,
        MatIconModule,
        CommonModule,
        RouterModule.forChild([
            {
                path: 'experianPayersUpload',
                component: ExperianPayersUploadComponent,
                canActivate: [AuthGuardWithForcedLogin]
            }
        ])
    ]
})
export class ExperianPayersUploadModule {
}