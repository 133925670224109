<div *ngIf="data">
<div class="row">
    <div class="col-md-12"></div>
    <div class="row">
        <div class="col-md-3"> {{data.functionCode}}</div>
        <div class="col-md-3"> {{data.name}}</div>

        <div *ngFor="let l of data.numbers">
            <div class="row">
                <div class="col-md-12">Numbers</div>
                <div class="row">
                    <div class="col-md-3"> {{l.qualifier}}</div>
                    <div class="col-md-3"> {{l.number}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>