import {Component, Input, OnInit} from '@angular/core';
import {EligibilityQueryV2} from "../../Models/EligibilityQueryV2";

@Component({
  selector: 'vp-eligibility-queryV2',
  templateUrl: './eligibility-queryV2.component.html',
  styleUrls: ['./eligibility-queryV2.component.css']
})
export class EligibilityQueryV2Component implements OnInit {

  constructor() { }

  @Input("eligibilityQuery") data! : EligibilityQueryV2


  ngOnInit(): void {
  }

}
