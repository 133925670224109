import {Component, Input, OnInit} from '@angular/core';
import {ProviderInformation} from "../../Models/ProviderInformation";

@Component({
  selector: 'vp-provider-information',
  templateUrl: './provider-information.component.html',
  styleUrls: ['./provider-information.component.css']
})
export class ProviderInformationComponent implements OnInit {

  constructor() { }

  @Input ("providerInformation") data!: ProviderInformation

  ngOnInit(): void {
  }

}
