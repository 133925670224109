




<mat-form-field>
    <mat-label>Search Alt Id</mat-label>
    <input matInput placeholder="search alternate id" (keyup)="search($event)" #input>
    <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
</mat-form-field>

<div class="card">
    <div class="card-header">
        {{pageTitle}}
    </div>
    <div class="card-body">

        <div class="card" *ngIf="errorMessage">
            <div class="card-header">
                Alert
            </div>
            <div class="card-body">
                <div class="row">
                    <div>
                        <div class="row">
                            <div class="alert alert-danger">
                                {{errorMessage}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(! data)">
            <div class="">No data found for ID: {{searchId}} Enter a valid search Id</div>
        </div>


        <div *ngIf="data">
            <div *ngIf="data">
                <div class="card"> <!-- start summary -->
                    <div class="card-header">
                        Summary
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3">Id</div>
                            <div class="col-md-6">{{data.id}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Is Eligible</div>
                            <div class="col-md-6">{{data.isEligible}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Is Error</div>
                            <div class="col-md-6">{{data.isError}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Is In Network</div>
                            <div class="col-md-6">{{data.isInNetwork}}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">Link Back URL</div>
                            <div class="col-md-6">{{data.linkBackUrl}}</div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="data.responseValidation">
                <vp-response-validation [experianResponseValidation]="data.responseValidation"></vp-response-validation>
            </div>


            <div *ngIf=" !isVersion2(data) &&   data.eligibilityQuery">
                <vp-eligibility-query [eligibilityQuery]="CastToVersion1(data.eligibilityQuery)"></vp-eligibility-query>
            </div>

            <div *ngIf=" isVersion2(data) &&  data.eligibilityQuery">
                <vp-eligibility-queryV2 [eligibilityQuery]="CastToVersion2(data.eligibilityQuery)"></vp-eligibility-queryV2>
            </div>

            <div *ngIf="data.eligibilityBenefitResponse">
                <vp-eligibility-benefit-response
                        [eligibilityBenefitResponse]="data.eligibilityBenefitResponse"></vp-eligibility-benefit-response>
            </div>

        </div>
    </div>
</div>