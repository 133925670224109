import {Component, Input, OnInit} from '@angular/core';
import {EligibilityBenefitAdditionalInformation} from "../../Models/EligibilityBenefitAdditionalInformation";

@Component({
  selector: 'vp-eligibility-benefit-additional-information',
  templateUrl: './eligibility-benefit-additional-information.component.html',
  styleUrls: ['./eligibility-benefit-additional-information.component.css']
})
export class EligibilityBenefitAdditionalInformationComponent implements OnInit {

  constructor() { }

  @Input ("eligibilityBenefitAdditionalInformation") data!: EligibilityBenefitAdditionalInformation;

  ngOnInit(): void {
  }

}
