<div *ngIf="data"> <!-- start Subscriber-->
    <div class="card">
        <div class="card-header">
            {{title}}
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-3">Date Of Birth</div>
                <div class="col-md-6"> {{data.dateOfBirth | date}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Member ID</div>
                <div class="col-md-6"> {{data.memberId}}</div>
            </div>

            <div class="row">
                <vp-experian-lookup [experianLookup]="data.relationship" [title]="'Relationship'"></vp-experian-lookup>
            </div>

            <div class="row">
                <div class="col-md-3">Gender</div>
                <div class="col-md-6"> {{data.gender}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">SSN</div>
                <div class="col-md-6"> {{data.ssn}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Plan Number</div>
                <div class="col-md-6"> {{data.planNumber}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Plan Begin Date</div>
                <div class="col-md-6"> {{data.planBeginDate}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Plan End Date</div>
                <div class="col-md-6"> {{data.planEndDate}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Group Number</div>
                <div class="col-md-6"> {{data.groupNumber}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Birth Sequence Number</div>
                <div class="col-md-6"> {{data.birthSequenceNumber}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Eligibility Begin Date</div>
                <div class="col-md-6"> {{data.eligibilityBeginDate}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Eligibility End Date</div>
                <div class="col-md-6"> {{data.eligibilityEndDate}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Eligibility Date</div>
                <div class="col-md-6"> {{data.eligibilityDate}}</div>
            </div>

            <div class="row">
                <div class="col-md-3">Prior Authorization Number</div>
                <div class="col-md-6"> {{data.priorAuthorizationNumber}}</div>
            </div>


            <div *ngIf="data.dates && data.dates.length > 0">
                <div class="card">
                    <div class="card-header">
                        Dates
                    </div>
                    <div class="card-body">
                        <div *ngFor="let k of data.dates">
                            <vp-qualified-date [qualifiedDate]="k"></vp-qualified-date>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="data.identifications && data.identifications.length > 0">
                <div class="card">
                    <div class="card-header">
                        Identifications
                    </div>
                    <div class="card-body">
                        <div *ngFor="let k of data.identifications">
                            <div class="row">
                                    <vp-identification [identification]="k"></vp-identification>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="data.name">
                <div class="card">
                    <div class="card-header">
                        Name
                    </div>

                    <div class="card-body">
                        <vp-name [entityName]="data.name"></vp-name>


                        <div *ngIf="data.name.type">
                            <div class="card">
                                <div class="card-header">
                                    Type
                                </div>
                                <div class="card-body">
                                    <vp-entity-type [entityType]="data.name.type"></vp-entity-type>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3">Prior Authorization Number</div>
                            <div class="col-md-3"> {{data.name.priorAuthorizationNumber}}</div>
                        </div>

                        <div *ngIf="data.name.identification">
                            <div class="card">
                                <div class="card-header">
                                    Identification
                                </div>
                                <div class="card-body">
                                        <vp-identification [identification]="data.name.identification"></vp-identification>
                                </div>
                            </div>
                        </div>

                     </div>
                </div>
            </div>

            <div *ngIf="data.dateRanges && data.dateRanges.length > 0 ">
                <div class="card">
                    <div class="card-header">
                        Date Range
                    </div>

                        <div class="card-body">

                            <div class="row">

                                <div *ngFor="let i of data.dateRanges">
                                    <vp-qualified-date-range [qualifiedDateRange]="i"></vp-qualified-date-range>
                                </div>
                            </div>
                        </div>
                </div>
            </div>


            <div *ngIf="data.address">
                <div class="card">
                    <div class="card-header">
                        Address
                    </div>
                    <div class="card-body">
                        <vp-postal-address [postalAddress]="data.address"></vp-postal-address>
                    </div>
                </div>
            </div>

            <div *ngIf="data.contacts && data.contacts.length > 0">
                <div class="card">
                    <div class="card-header">
                        Contacts
                    </div>
                    <div class="card-body">
                        <div *ngFor="let k of data.contacts">
                            <vp-entity-contact [entityContact]="k"></vp-entity-contact>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="data.diagnoses && data.diagnoses.length > 0">
                <div class="card"> <!-- start diagnoses-->
                    <div class="card-header">
                        Diagnoses
                    </div>
                    <div *ngIf="data.diagnoses">
                        <div class="card-body">
                            <div *ngFor="let i of data.diagnoses">
                                <vp-experian-lookup [title]="'Diagnosis'" [experianLookup]="i"></vp-experian-lookup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="data.providerInfo">
                <div class="card"> <!-- start provider info-->
                    <div class="card-header">
                        Provider Info
                    </div>
                    <div>
                        <div class="card-body">
                            <vp-provider-information [providerInformation]="data.providerInfo"></vp-provider-information>
                        </div>
                    </div>
                </div> <!-- end provider info-->
            </div>

            <div *ngIf="data.requestValidations && data.requestValidations.length > 0">
                <div class="card"> <!-- start requestValidation-->
                    <div class="card-header">
                        Validation
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12"></div>

                            <div *ngFor="let i of data.requestValidations">
                                <div class="row">
                                    <vp-request-validation [requestValidation]="i"></vp-request-validation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div> <!-- end Subscriber-->