<div *ngIf="entityName">
    <div class="row">
        <div class="col-md-12"></div>
        <div class="row">
            <div class="col-md-3">Last Name</div>
            <div class="col-md-3"> {{entityName.lastName}}</div>
        </div>
        <div class="row">
            <div class="col-md-3">First Name</div>
            <div class="col-md-3"> {{entityName.firstName}}</div>
        </div>
        <div class="row">
            <div class="col-md-3">Middle Name</div>
            <div class="col-md-3"> {{entityName.middleName}}</div>
        </div>
        <div class="row">
            <div class="col-md-3">Prefix</div>
            <div class="col-md-3"> {{entityName.prefix}}</div>
        </div>
        <div class="row">
            <div class="col-md-3">Suffix</div>
            <div class="col-md-3"> {{entityName.suffix}}</div>
        </div>
    </div>
</div>