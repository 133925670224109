<div *ngIf="data"> <!-- start responseValidation-->
    <div class="card">
        <div class="card-header">
            Response Validation
        </div>
        <div class="card-body">

            <div class="row">
                <div class="col-md-12"></div>

                <div class="row">
                    <div class="col-md-3">Is HOM EPO</div>
                    <div class="col-md-3"> {{data.isHomEpo}}</div>
                </div>

                <div class="row">
                    <div class="col-md-3">Is Active Coverage</div>
                    <div class="col-md-3"> {{data.isActiveCoverage}}</div>
                </div>


                <div class="row">
                    <div class="col-md-3">Out Of Network</div>
                    <div class="col-md-3"> {{data.outOfNetwork}}</div>
                </div>

                <div class="row">
                    <div class="col-md-3">is CTCA Approved</div>
                    <div class="col-md-3"> {{data.isCtcaApproved}}</div>
                </div>

                <div *ngIf="data?.requestValidationSummary?.length">
                    <div class="card">
                        <div class="card-header">
                            Validations
                        </div>
                        <div class="card-body">
                            <div *ngFor="let i of data.requestValidationSummary">
                                <div class="card">
                                    <div class="card-header">
                                        Validation
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-3">Valid Request</div>
                                            <div class="col-md-3"> {{i.validRequest}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">Rejection Reason</div>
                                            <div class="col-md-3"> {{i.rejectReason.code}}</div>
                                            <div class="col-md-6"> {{i.rejectReason.description}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">Followup Action</div>
                                            <div class="col-md-3"> {{i.follupAction.code}}</div>
                                            <div class="col-md-6"> {{i.follupAction.description}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="data?.servicesTypes?.length">
                    <div class="card">
                        <div class="card-header">
                            Service Types
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div *ngFor="let i of data.servicesTypes">

                                    <div class="row">
                                        <vp-experian-lookup [title]="'service Type'"
                                                            [experianLookup]="i"></vp-experian-lookup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div *ngIf="data?.coverageLevels?.length">
                    <div class="card">
                        <div class="card-header">
                            Coverage Levels
                        </div>

                        <div *ngIf="data.coverageLevels"> <!-- start serviceType-->
                            <div class="card-body">
                                <div class="row">
                                    <div *ngFor="let i of data.coverageLevels">
                                        <div class="row">
                                            <vp-experian-lookup [title]="'Coverage Level'"
                                                                [experianLookup]="i"></vp-experian-lookup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>