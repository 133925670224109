<div *ngIf="data">
<div class="row">
    <div class="col-md-12">Additional Info</div>

    <div class="row">
        <div class="col-md-3">Qualifier</div>
        <div class="col-md-6"> {{data.qualifier}}</div>
    </div>

    <div class="row">
        <div class="col-md-3">Code Category</div>
        <div class="col-md-6"> {{data.codeCategory}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">Industry Code</div>
        <div class="col-md-6"> {{data.industryCode}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">Text</div>
        <div class="col-md-6"> {{data.text}}</div>
    </div>
</div>
</div>