

<div *ngIf="data">
    <div class="card">
        <div class="card-header">
            Eligibility Query
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">Carrier Id</div>
                <div class="col-md-6">{{data.carrierId}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">NPI</div>
                <div class="col-md-6">{{data.npi}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Passport Payer Mnemonic</div>
                <div class="col-md-6">{{data.passportPayerMnemonic}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Payer Name</div>
                <div class="col-md-6">{{data.payerName}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">Prospect ID</div>
                <div class="col-md-6">{{data.prospectId}}</div>
            </div>
            <div class="row">
                <div class="col-md-3">State</div>
                <div class="col-md-6">{{data.state}}</div>
            </div>

            <div class="card">
                <div class="card-header">
                    Experian Coded Extended Fields
                </div>
                <div *ngIf="data.experianCodedExtendedFields">
                    <div class="card-body">

                        <div *ngFor="let i of data.experianCodedExtendedFields | keyvalue">
                            <div class="row">
                                <div class="col-md-3">{{i.key}}</div>
                                <div class="col-md-6">{{ i.value }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>