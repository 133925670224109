
<div *ngIf="data">
<div class="row">
    <div class="col-md-3">Qualifier</div>
    <div class="col-md-3"> {{data.qualifier}}</div>
</div>
<div class="row">
    <div class="col-md-3">Amount</div>
    <div class="col-md-3"> {{data.amount}}</div>
</div>
<div class="row">
    <div class="col-md-3">Description</div>
    <div class="col-md-3"> {{data.description}}</div>
</div>
</div>