import {Component, OnInit} from '@angular/core';
import {ExperianTransaction} from "../../Models/ExperianTransaction";
import {Subscription} from "rxjs";
import {DataService} from "../../shared/data.service";
import {ActivatedRoute} from "@angular/router";
import {DatePipe} from "@angular/common";
import {AppError} from "../../Models/AppError";
import {HttpUrlEncodingCodec} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'eligibility-summary-status-dashboard',
    templateUrl: './transaction.component.html',
    styleUrls: ['./transaction.component.css']
})

export class TransactionComponent implements OnInit {

    title = '';
    errorMessage = '';

    data!: ExperianTransaction;
    unsafe_response!: SafeHtml;
    unsafe_request!: SafeHtml;

    private dataSub!: Subscription;

    HttpUrlEncodingCodec = new HttpUrlEncodingCodec();

    constructor(private dataService: DataService, private route: ActivatedRoute,
                private datePipe: DatePipe, private sanitizer: DomSanitizer) {
    }


    ngOnInit(): void {

        let id = this.route.snapshot.paramMap.get('Id');
        if (id != null) {
            this.dataSub = this.dataService.getExperianTransactionForId(this.HttpUrlEncodingCodec.decodeValue(id))
                .subscribe({
                    next: (responseData: ExperianTransaction | AppError) => {
                        this.data = <ExperianTransaction>responseData;
                        this.unsafe_response = this.sanitizer.bypassSecurityTrustHtml(this.data.response);
                        this.unsafe_request = this.sanitizer.bypassSecurityTrustHtml(this.data.request);
                    },
                    error: (err: AppError) => {
                        console.error('TransactionComponent Observer got an error: ' + JSON.stringify(err));
                        this.errorMessage = err.friendlyMessage;
                    },
                    complete: () => {
                        console.log('TransactionComponent Observer got a complete notification');
                    }
                })
        }
    };

    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }


}
