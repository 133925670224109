<div class="card">
    <div class="card-header">
        {{pageTitle}}
    </div>
    <div class="card-body">




    <mat-form-field>
        <mat-label>Search Alt Id</mat-label>
        <input matInput placeholder="search alternate id" (keyup)="search($event)" #input>
    </mat-form-field>


    <div class="card" *ngIf="errorMessage">
        <div class="card-header">
            Alert
        </div>
        <div class="card-body">
            <div class="row">
                <div>
                    <div class="row">
                        <div class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(! data)">
        <div class="">No data found for ID: {{searchId}} Enter a valid search Id</div>
    </div>

    <div *ngIf="data">
        <div class="card">
            <div class="card-header">
                Summary
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2">Historical OID</div>
                    <div class="col-md-6">{{data.oid}}</div>
                </div>
                <div class="row">
                    <div class="col-md-2">Carrier</div>
                    <div class="col-md-6">{{data.carrier}}</div>
                </div>
                <div class="row">
                    <div class="col-md-2">Type</div>
                    <div class="col-md-6">{{data.insuranceType}}</div>
                </div>
            </div>
        </div>


        <div class="row tableWrapper">
            <div class="tableHeader row">
                Overview
            </div>

            <div class="row">
                <table mat-table [dataSource]="overallDatasource">

                    <ng-container matColumnDef="eligibilityOrBenefitCode">
                        <th mat-header-cell *matHeaderCellDef>Eligibility or Benefit Code</th>
                        <td mat-cell *matCellDef="let row">{{row.eligibilityOrBenefitCode}}</td>
                    </ng-container>

                    <ng-container matColumnDef="coverageLevel">
                        <th mat-header-cell *matHeaderCellDef>Coverage Level</th>
                        <td mat-cell *matCellDef="let row">{{row.coverageLevel}}</td>
                    </ng-container>

                    <ng-container matColumnDef="services">
                        <th mat-header-cell *matHeaderCellDef>Service(s)</th>
                        <td mat-cell *matCellDef="let row">{{row.services}}</td>
                    </ng-container>

                    <ng-container matColumnDef="period">
                        <th mat-header-cell *matHeaderCellDef>Period</th>
                        <td mat-cell *matCellDef="let row">{{row.period}}</td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let row">{{row.amount | currency}}</td>
                    </ng-container>

                    <ng-container matColumnDef="percentAsDecimal">
                        <th mat-header-cell *matHeaderCellDef>% as Decimal</th>
                        <td mat-cell *matCellDef="let row">{{row.percentAsDecimal | percent}}</td>
                    </ng-container>

                    <ng-container matColumnDef="messages">
                        <th mat-header-cell *matHeaderCellDef>Messages</th>
                        <td mat-cell *matCellDef="let row">{{row.message}}</td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayColumn;"
                        [ngClass]="{gray: even}"></tr>

                </table>
            </div>

        </div>


        <div class="row tableWrapper">
            <div class="tableHeader row">
                In Network
            </div>

            <div class="row">
                <div class="mat-elevation-z0">
                    <table mat-table [dataSource]="inNetworkDatasource">

                        <ng-container matColumnDef="eligibilityOrBenefitCode">
                            <th mat-header-cell *matHeaderCellDef>Eligibility or Benefit Code</th>
                            <td mat-cell *matCellDef="let row">{{row.eligibilityOrBenefitCode}}</td>
                        </ng-container>

                        <ng-container matColumnDef="coverageLevel">
                            <th mat-header-cell *matHeaderCellDef>Coverage Level</th>
                            <td mat-cell *matCellDef="let row">{{row.coverageLevel}}</td>
                        </ng-container>

                        <ng-container matColumnDef="services">
                            <th mat-header-cell *matHeaderCellDef>Service(s)</th>
                            <td mat-cell *matCellDef="let row">{{row.services}}</td>
                        </ng-container>

                        <ng-container matColumnDef="period">
                            <th mat-header-cell *matHeaderCellDef>Period</th>
                            <td mat-cell *matCellDef="let row">{{row.period}}</td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td mat-cell *matCellDef="let row">{{row.amount | currency}}</td>
                        </ng-container>

                        <ng-container matColumnDef="percentAsDecimal">
                            <th mat-header-cell *matHeaderCellDef>% as Decimal</th>
                            <td mat-cell *matCellDef="let row">{{row.percentAsDecimal | percent}}</td>
                        </ng-container>

                        <ng-container matColumnDef="messages">
                            <th mat-header-cell *matHeaderCellDef>Messages</th>
                            <td mat-cell *matCellDef="let row">{{row.message}}</td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
                        <tr mat-row *matRowDef="let row; let even = even; columns: displayColumn;"
                            [ngClass]="{gray: even}"></tr>

                    </table>
                </div>
            </div>
        </div>

        <div class="row tableWrapper">
            <div class="tableHeader row">
                Out Of Network
            </div>

            <div class="row">
                <div class="mat-elevation-z0">
                    <table mat-table [dataSource]="outOfNetworkDatasource">

                        <ng-container matColumnDef="eligibilityOrBenefitCode">
                            <th mat-header-cell *matHeaderCellDef>Eligibility or Benefit Code</th>
                            <td mat-cell *matCellDef="let row">{{row.eligibilityOrBenefitCode}}</td>
                        </ng-container>

                        <ng-container matColumnDef="coverageLevel">
                            <th mat-header-cell *matHeaderCellDef>Coverage Level</th>
                            <td mat-cell *matCellDef="let row">{{row.coverageLevel}}</td>
                        </ng-container>

                        <ng-container matColumnDef="services">
                            <th mat-header-cell *matHeaderCellDef>Service(s)</th>
                            <td mat-cell *matCellDef="let row">{{row.services}}</td>
                        </ng-container>

                        <ng-container matColumnDef="period">
                            <th mat-header-cell *matHeaderCellDef>Period</th>
                            <td mat-cell *matCellDef="let row">{{row.period}}</td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                            <th mat-header-cell *matHeaderCellDef>Amount</th>
                            <td mat-cell *matCellDef="let row">{{row.amount | currency}}</td>
                        </ng-container>

                        <ng-container matColumnDef="percentAsDecimal">
                            <th mat-header-cell *matHeaderCellDef>% as Decimal</th>
                            <td mat-cell *matCellDef="let row">{{row.percentAsDecimal | percent}}</td>
                        </ng-container>

                        <ng-container matColumnDef="messages">
                            <th mat-header-cell *matHeaderCellDef>Messages</th>
                            <td mat-cell *matCellDef="let row">{{row.message}}</td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
                        <tr mat-row *matRowDef="let row; let even = even; columns: displayColumn;"
                            [ngClass]="{gray: even}"></tr>

                    </table>
                </div>
            </div>


        </div>
    </div>
    </div>
</div>


