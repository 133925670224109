<div class="card" *ngIf="errorMessage">
    <div class="card-header">
        Alert
    </div>
    <div class="card-body">
        <div class="row">
            <div>
                <div class="row">
                    <div class="alert alert-danger">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<mat-form-field>
    <mat-label>Filter Table</mat-label>
    <input matInput placeholder="search" (keyup)="applyFilter($event)" #input>
</mat-form-field>


<div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="datasource">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">ID</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <ng-container matColumnDef="prospectId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">Prospect ID</th>
            <td mat-cell *matCellDef="let row">{{row.prospectId}}</td>
        </ng-container>

        <ng-container matColumnDef="carrierId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">Carrier ID</th>
            <td mat-cell *matCellDef="let row">{{row.carrierId}}</td>
        </ng-container>

        <ng-container matColumnDef="payer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">Payer</th>
            <td mat-cell *matCellDef="let row">{{row.payer}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">State</th>
            <td mat-cell *matCellDef="let row">{{row.state}}</td>
        </ng-container>

        <ng-container matColumnDef="npi">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">NPI</th>
            <td mat-cell *matCellDef="let row">{{row.npi}}</td>
        </ng-container>

        <ng-container matColumnDef="isError">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">Is Error</th>
            <td mat-cell *matCellDef="let row">{{row.isError}}</td>
        </ng-container>

        <ng-container matColumnDef="isEligible">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">Is Eligible</th>
            <td mat-cell *matCellDef="let row">{{row.isEligible}}</td>
        </ng-container>


        <ng-container  matColumnDef="response">
            <th mat-header-cell  *matHeaderCellDef mat-sort-header="">Response</th>
            <td mat-cell *matCellDef="let row">
                <a [routerLink]="['/eligibilitysummary/v1/geteligibilitysummary', HttpUrlEncodingCodec.encodeValue(row.id)]">Details</a>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
        <tr mat-row *matRowDef="let row; let even = even; columns: displayColumn;" [ngClass]="{gray: even}"></tr>

        <!-- no data in filter -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="12">The filter value "{{input.value}}" does not match any values</td>
        </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5,10,25,100]" aria-label="select page size"></mat-paginator>
</div>



