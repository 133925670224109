import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";

import {DailyTransactionsSummaryComponent} from "./daily-transactions-summary/daily-transactions-summary.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {OverlayModule} from "@angular/cdk/overlay";
import {DialogModule} from "@angular/cdk/dialog";
import { TransactionComponent } from './transaction/transaction.component';
import {MatGridListModule} from "@angular/material/grid-list";
import {AuthGuardWithForcedLogin} from "../core/auth-guard-with-forced-login.service";

@NgModule({
    declarations: [
        DailyTransactionsSummaryComponent,
        TransactionComponent
    ],

    exports: [
        DailyTransactionsSummaryComponent,
    ],

    imports: [

        RouterModule.forChild([

            {
                path: 'dailyTransactions/:transactionDate/:filter',
                component: DailyTransactionsSummaryComponent,
                canActivate:  [AuthGuardWithForcedLogin]
            },
            {
                path: 'dailyTransactions/:transactionDate',
                component: DailyTransactionsSummaryComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },


            {
                path: 'dailyTransaction/:Id',
                component: TransactionComponent,
                canActivate: [AuthGuardWithForcedLogin]
            },



        ]),
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        OverlayModule,
        DialogModule,
        MatGridListModule
    ]
})

export class DailyTransactionModule {
}
