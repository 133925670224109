import {Component, Input, OnInit} from '@angular/core';
import {QualifiedDate} from "../../Models/QualifiedDate";

@Component({
  selector: 'vp-qualified-date',
  templateUrl: './qualified-date.component.html',
  styleUrls: ['./qualified-date.component.css']
})
export class QualifiedDateComponent implements OnInit {

  constructor() { }

  @Input ("qualifiedDate") data!: QualifiedDate;

  ngOnInit(): void {
  }

}
