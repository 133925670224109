import {Component, OnInit} from '@angular/core';
import {DataService} from "../shared/data.service";
import {AppError} from "../Models/AppError";
import {Subscription} from "rxjs";
import {TransactionSummaryEntity} from "../Models/TransactionSummaryEntity";
import {DatePipe} from "@angular/common";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'transaction-summary-dashboard',
    templateUrl: './transaction-summary-dashboard.component.html',
    styleUrls: ['./transaction-summary-dashboard.component.css']
})

export class TransactionSummaryDashboardComponent implements OnInit {

    constructor(private dataService: DataService, private datePipe: DatePipe) {
    }

    pageTitle = 'Transaction Summary';
    errorMessage = '';

    displayColumn: string[] = ['transactionDate', 'errors', 'eligible', 'notEligible', 'total'];
    datasource!: MatTableDataSource<TransactionSummaryEntity>
    data: TransactionSummaryEntity[] = [];

    private dataSub!: Subscription;

    ngOnInit(): void {

        let endDate: Date = new Date();
        let startDate: Date = new Date(new Date().setDate(endDate.getDate() - 90));

        this.dataSub = this.dataService.getTransactionSummary(startDate, endDate)
            .subscribe({
                next: (responseData: TransactionSummaryEntity[] | AppError) => {
                    this.data = <TransactionSummaryEntity[]>responseData;
                    this.datasource = new MatTableDataSource<TransactionSummaryEntity>(this.data);
                },
                error: (err: AppError) => {
                    console.error('TransactionSummary Observer got an error: ' + JSON.stringify(err));
                    this.errorMessage = err.friendlyMessage;
                },
                complete: () => {
                    console.log('TransactionSummary Observer got a complete notification');
                }
            });
    }


    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }


    public EncodeDate(data: Date | undefined): string {
        if (data === undefined || data === null) {
            return '';
        }
        let dte = this.transformDate(data);
        return encodeURIComponent(dte);
    }


    public transformDate(date: Date | number | string | undefined): string {
        if (date === undefined) {
            return '';
        }
        let val = this.datePipe.transform(date, 'yyyy-MM-dd'); //whatever format you need.
        if (val === null) {
            return '';
        }
        return val;
    }

}
