import {Component} from '@angular/core';
import {AuthService} from "../core/auth.service";

@Component({
    templateUrl: './welcome.component.html'
})
export class WelcomeComponent {
    public pageTitle = 'Welcome';

    constructor(private authService: AuthService)
    {
    }

    get hasValidToken() { return this.authService.hasValidToken(); }

}
