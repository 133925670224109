<div *ngIf="data">
<div class="row">
    <div class="col-md-12"></div>
    <div class="row">
        <div class="col-md-3">Line 1</div>
        <div class="col-md-6"> {{data.line1}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">Line 2</div>
        <div class="col-md-6"> {{data.line2}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">City</div>
        <div class="col-md-6"> {{data.city}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">State Code</div>
        <div class="col-md-6"> {{data.stateCode}}</div>
    </div>
    <div class="row">
        <div class="col-md-6">Postal Code</div>
        <div class="col-md-3"> {{data.postalCode}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">Country Code</div>
        <div class="col-md-6"> {{data.countryCode}}</div>
    </div>
    <div class="row">
        <div class="col-md-3">Country Subdivision Code</div>
        <div class="col-md-6"> {{data.countrySubdivisionCode}}</div>
    </div>
</div>
</div>