<div class="card" *ngIf="errorMessage">
  <div class="card-header">Alert</div>
  <div class="card-body">
    <div class="row">
      <div>
        <div class="row">
          <div class="alert alert-danger">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card">
  <div class="card-header">Experian Payers Upload</div>
  <div class="card-body">
    <p class="card-text">File: {{ fileName }}</p>
    <input
      type="file"
      class="file-input"
      accept=".xlsx"
      (change)="onFileSelected($event)"
      name="experianPayersUpload"
      #fileUpload
    />
    <br />
    <a class="btn btn-primary mt-3" (click)="onButtonClick()">Upload</a>
    <p class="card-text upload" *ngIf="uploadClicked != ''">
      {{ uploadClicked }}
    </p>
    <p class="card-text upload" *ngIf="uploadProgress > 0">
      File is {{ uploadProgress }}% uploaded
    </p>
  </div>
</div>
