<div>

    <div class="card mat-elevation-z8" *ngIf="errorMessage">
        <div class="card-header">
            Alert
        </div>
        <div class="card-body">
            <div class="row">
                <div>
                    <div class="row">
                        <div class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="card mat-elevation-z8">
        <div class="card-header">
            {{pageTitle}}
        </div>
        <div class="card-body">


            <div>
                <table mat-table matSort [dataSource]="datasource">

                    <ng-container matColumnDef="transactionDate">
                        <th mat-header-cell *matHeaderCellDef>Date</th>
                        <td mat-cell *matCellDef="let row">
                            <a [routerLink]="['/dailyTransactions', EncodeDate(row.transactionDate)]"
                               routerLinkActive='active'>{{transformDate(row.transactionDate)}}</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="errors">
                        <th mat-header-cell *matHeaderCellDef>Errors</th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.errors > 0">
                                <a [routerLink]="['/dailyTransactions', EncodeDate(row.transactionDate), 'errors']"
                                   routerLinkActive='active'>{{row.errors}}</a>
                            </div>
                            <div *ngIf="row.errors == 0" >0</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="eligible">
                        <th mat-header-cell *matHeaderCellDef>Eligible</th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.eligible > 0">
                                <a [routerLink]="['/dailyTransactions', EncodeDate(row.transactionDate), 'eligible']"
                                   routerLinkActive='active'>{{row.eligible}}</a>
                            </div>
                            <div *ngIf="row.eligible == 0" >0</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="notEligible">
                        <th mat-header-cell *matHeaderCellDef>NotEligible</th>
                        <td mat-cell *matCellDef="let row">
                            <div *ngIf="row.notEligible > 0">
                            <a  [routerLink]="['/dailyTransactions', EncodeDate(row.transactionDate), 'notEligible']"
                               routerLinkActive='active'>{{row.notEligible}}</a>
                            </div>
                            <div *ngIf="row.notEligible == 0" >0</div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef>Total</th>
                        <td mat-cell *matCellDef="let row">{{row.total}}</td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayColumn"></tr>
                    <tr mat-row *matRowDef="let row; let even = even; columns: displayColumn;"
                        [ngClass]="{gray: even}"></tr>

                    <!-- no data in filter -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="12">No Transaction data available</td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</div>


