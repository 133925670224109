import {Component, Input, OnInit} from '@angular/core';
import {BenefitMember} from "../../Models/BenefitMember";

@Component({
  selector: 'vp-benefit-member',
  templateUrl: './benefit-member.component.html',
  styleUrls: ['./benefit-member.component.css']
})
export class BenefitMemberComponent implements OnInit {

  constructor() { }

  @Input("benefitMember") data! : BenefitMember
  @Input("memberTypeTitle") title! : string



  ngOnInit(): void {
  }

}
