<div *ngIf="data">
<div class="row">
    <div class="col-md-3">Qualifier</div>
    <div class="col-md-2"> {{data.qualifier}}</div>
</div>
<div class="row">
    <div class="col-md-3">Id</div>
    <div class="col-md-2"> {{data.id}}</div>
</div>
<div class="row">
    <div class="col-md-3">Provider Code</div>
    <div class="col-md-2"> {{data.providerCode}}</div>
</div>
<div class="row">
    <div class="col-md-3">description</div>
    <div class="col-md-2"> {{data.description}}</div>
</div>
</div>