import {Component, Input, OnInit} from '@angular/core';
import {RelatedEntity} from "../../Models/RelatedEntity";

@Component({
  selector: 'pv-eligibility-related-entity',
  templateUrl: './eligibility-related-entity.component.html',
  styleUrls: ['./eligibility-related-entity.component.css']
})
export class EligibilityRelatedEntityComponent implements OnInit {

  constructor() { }

  @Input() data!: RelatedEntity;

  ngOnInit(): void {
  }

}
