
<div *ngIf="data">
    <div class="row">
        <div class="col-md-3">{{title}}</div>
        <div class="col-md-3"> {{data.code}}</div>
        <div class="col-md-6"> {{data.description}}</div>
    </div>
</div>


