import {AuthConfig} from 'angular-oauth2-oidc';
import { environment } from '../../environments/environment';

export const authConfig: AuthConfig = {

    issuer: environment.ping_issuer,
    clientId: environment.ping_clientId,
    responseType: 'code',
    redirectUri: `${window.location.origin}/index.html`,
   // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    scope: 'openid profile email', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
   // silentRefreshTimeout: 5000, // For faster testing
   // timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator : 'semicolon', // Real semicolon gets mangled by Duende ID Server's URI encoding

    //disable for local testing
    requireHttps: environment.ping_requireHttps,

    preserveRequestedRoute:true,

    //must be set to false for ping one - as the issue url contains the client id
    strictDiscoveryDocumentValidation: false

};
