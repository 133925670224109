import {Component, Input, OnInit} from '@angular/core';
import {MedicalProcedure} from "../../Models/MedicalProcedure";

@Component({
  selector: 'vp-medical-procedure',
  templateUrl: './procedure.component.html',
  styleUrls: ['./procedure.component.css']
})
export class ProcedureComponent implements OnInit {

  constructor() { }

  @Input("medicalProcedure") data!: MedicalProcedure;

  ngOnInit(): void {
  }

}
