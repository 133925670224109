import {Component, Input, OnInit} from '@angular/core';
import {EligibilityBenefitsResponse} from "../../Models/EligibilityBenefitsResponse";

@Component({
  selector: 'vp-eligibility-benefit-response',
  templateUrl: './eligibility-benefit-response.component.html',
  styleUrls: ['./eligibility-benefit-response.component.css']
})
export class EligibilityBenefitResponseComponent implements OnInit {

  constructor() { }


  @Input("eligibilityBenefitResponse") data! : EligibilityBenefitsResponse


  ngOnInit(): void {
  }

}
