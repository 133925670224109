import {ExperianResponseValidation} from "./ExperianResponseValidation";
import {EligibilityBenefitsResponse} from "./EligibilityBenefitsResponse";
import {EligibilityQuery, IEligibilityQuery} from "./EligibilityQuery";
import {EligibilityQueryV2} from "./EligibilityQueryV2"


export interface IEligibilityResponse {
  typeVersion: number;
  id: number;
  isError: boolean;
  isEligible: boolean;
  isInNetwork: boolean;
  linkBackUrl: string;
  responseValidation: ExperianResponseValidation;
  eligibilityBenefitResponse: EligibilityBenefitsResponse;
  eligibilityQuery: IEligibilityQuery;
}



export class EligibilityResponse implements IEligibilityResponse {
  typeVersion!: number;
  id!: number;
  isError!: boolean;
  isEligible!: boolean;
  isInNetwork!: boolean;
  linkBackUrl!: string;
  responseValidation!: ExperianResponseValidation;
  eligibilityBenefitResponse!: EligibilityBenefitsResponse;
  experianX12Response!: string;
  eligibilityQuery!: EligibilityQuery
}


export class EligibilityResponseV2 implements IEligibilityResponse {
  typeVersion!: number;
  id!: number;
  isError!: boolean;
  isEligible!: boolean;
  isInNetwork!: boolean;
  linkBackUrl!: string;
  responseValidation!: ExperianResponseValidation;
  eligibilityBenefitResponse!: EligibilityBenefitsResponse;
  experianResponseX12!: string;
  experianResponseText!: string;
  eligibilityQuery!: EligibilityQueryV2


}