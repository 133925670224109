<div *ngIf="data"> <!-- start benefitInfos-->
    <div class="card">
        <div class="card-header">
            Benefit Information
        </div>
        <div class="card-body">
            <div *ngFor="let i of data">
                <div class="card">
                    <div class="card-header">
                        Benefit
                    </div>
                    <div class="card-body">

                        <div *ngIf="i?.additionalInfo">
                            <vp-eligibility-benefit-additional-information
                                    [eligibilityBenefitAdditionalInformation]="i.additionalInfo"></vp-eligibility-benefit-additional-information>
                        </div>

                        <div *ngIf="i?.amount">
                            <div class="row">
                                <div class="col-md-3">Amount</div>
                                <div class="col-md-6"> {{i?.amount}}</div>
                            </div>
                        </div>

                        <div *ngIf="i?.authorizationCertificationRequired">
                            <vp-experian-lookup [title]="'Authorization Certification Required'"
                                                [experianLookup]="i.authorizationCertificationRequired"></vp-experian-lookup>
                        </div>

                        <div *ngIf="i?.coverageLevel">
                            <vp-experian-lookup [title]="'Coverage Level'"
                                                [experianLookup]="i.coverageLevel"></vp-experian-lookup>
                        </div>

                        <div *ngIf="i?.infoType">
                            <vp-experian-lookup [title]="'Info Type'"
                                                [experianLookup]="i.infoType"></vp-experian-lookup>
                        </div>

                        <div *ngIf="i?.inPlanNetwork">
                            <vp-experian-lookup [title]="'In Plan Network'"
                                                [experianLookup]="i.inPlanNetwork"></vp-experian-lookup>
                        </div>

                        <div *ngIf="i?.insuranceType">
                            <vp-experian-lookup [title]="'Insurance Type'"
                                                [experianLookup]="i.insuranceType"></vp-experian-lookup>
                        </div>

                        <div *ngIf="i?.messages?.length">
                            <div class="card">
                                <div class="card-header">
                                    Messages
                                </div>
                                <div class="card-body">
                                    <div *ngFor="let j of i?.messages">
                                        <div class="row">
                                            <div class="col-md-9">{{ j}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="i?.percentage">
                            <div class="card">
                                <div class="card-header">
                                    Percentage
                                </div>
                                <div class="card-body">
                                    <div class="col-md-6"> {{i?.percentage}}</div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="i?.planCoverageDescription">
                            <div class="card">
                                <div class="card-header">
                                    Plan Coverage Description
                                </div>
                                <div class="card-body">
                                    <div class="col-md-6"> {{i?.planCoverageDescription}}</div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="i?.date">
                            <div class="row">
                                <div class="col-md-3">Date</div>
                                <div *ngFor="let j of i?.date">
                                    <vp-qualified-date [qualifiedDate]="j"></vp-qualified-date>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="i?.dateRange">
                            <div class="card">
                                <div class="card-header">
                                    Date Range
                                </div>
                                <div class="card-body">

                                    <div class="row">
                                        <div class="col-md-3"></div>
                                        <div *ngFor="let j of i?.dateRange">
                                            <vp-qualified-date-range [qualifiedDateRange]="j"></vp-qualified-date-range>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="i?.identification">
                            <div class="card">
                                <div class="card-header">
                                    Identification
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3"></div>
                                        <div *ngFor="let j of i?.identification">
                                            <vp-identification [identification]="j"></vp-identification>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="i?.procedure">
                            <div class="card">
                                <div class="card-header">
                                    Procedures
                                </div>
                                <div class="card-body">
                                    <div class="col-md-3"></div>
                                    <vp-medical-procedure [medicalProcedure]="i.procedure"></vp-medical-procedure>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="i?.quantity">
                            <div class="card">
                                <div class="card-header">
                                    Quantity
                                </div>
                                <div class="card-body">
                                    <div class="col-md-3"></div>
                                    <vp-qualified-amount [qualifiedAmount]="i.quantity"></vp-qualified-amount>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="i?.relatedEntity"> <!-- start relatedEntity-->
                            <div class="card">
                                <div class="card-header">
                                    Related Entity
                                </div>

                                <div *ngFor="let j of i?.relatedEntity">
                                    <pv-eligibility-related-entity [data]="j"></pv-eligibility-related-entity>
                                </div>
                            </div>
                        </div>  <!-- end relatedEntity-->

                        <div *ngIf="i?.serviceTypeCount || ( i?.serviceTypes && i.serviceTypes.length > 0)">
                            <div class="card"><!-- start serviceType-->
                                <div class="card-header">
                                    Service Types
                                </div>
                                <div *ngIf="i?.serviceTypeCount">
                                    <div class="row">
                                        <div class="col-md-3">Service Type Count</div>
                                        <div class="col-md-3"> {{i?.serviceTypeCount}}</div>
                                    </div>
                                </div>

                                <div *ngIf="i?.serviceTypes">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-12"></div>

                                            <div *ngFor="let j of i?.serviceTypes">
                                                <vp-experian-lookup [title]="'Valid Request'"
                                                                    [experianLookup]="j"></vp-experian-lookup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> <!-- end serviceType-->
                        </div>

                        <div *ngIf="i?.requestValidation">
                            <div class="card">
                                <div class="card-header">
                                    Validation
                                </div>

                                <div class="card-body">
                                    <div class="row">
                                        <div *ngFor="let j of i?.requestValidation">
                                            <div class="col-md-12">Request Validation</div>
                                            <div class="row">
                                                <vp-request-validation [requestValidation]="j"></vp-request-validation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- end benefitInfos-->
