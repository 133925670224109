import {Component, Input, OnInit} from '@angular/core';
import {Identification} from "../../Models/Identification";

@Component({
  selector: 'vp-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.css']
})
export class IdentificationComponent implements OnInit {

  constructor() { }

  @Input ("identification") data!: Identification;

  ngOnInit(): void {
  }

}
