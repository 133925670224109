import {Component, Input, OnInit} from '@angular/core';
import {Lookup} from "../../Models/Lookup";
import {ExperianLookupItem} from "../../Models/ExperianLookupItem";

@Component({
  selector: 'vp-experian-lookup',
  templateUrl: './experian-lookup.component.html',
  styleUrls: ['./experian-lookup.component.css']
})
export class ExperianLookupComponent implements OnInit {

  constructor() { }

  @Input ("title") title!: string
  @Input ("experianLookup") data!: ExperianLookupItem
  ngOnInit(): void {
  }

}
