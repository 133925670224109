import {Component, Input, OnInit} from '@angular/core';
import {EligibilityQuery} from "../../Models/EligibilityQuery";

@Component({
  selector: 'vp-eligibility-query',
  templateUrl: './eligibility-query.component.html',
  styleUrls: ['./eligibility-query.component.css']
})
export class EligibilityQueryComponent implements OnInit {

  constructor() { }

  @Input("eligibilityQuery") data! : EligibilityQuery


  ngOnInit(): void {
  }

}
