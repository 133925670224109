import { Component } from "@angular/core";
import { Observable } from "rxjs";

import { AuthService } from "./core/auth.service";
@Component({
  selector: "app-menu",
  template: ` <nav class="navbar navbar-expand navbar-light bg-light">
    <a class="navbar-brand">{{ pageTitle }}</a>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="['/welcome']"
        >
          Home
        </a>
      </li>
      <!--  <li class='nav-item'><a class='nav-link' routerLinkActive='active'
                                           [routerLinkActiveOptions]="{exact: true}"
                                           [routerLink]="['/reimbursementHistory/2134/1811984388/asc']">test Reimbursement
                       History</a>
                   </li>
           -->
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/eligibilitysummary/v1/geteligibilitysummary/0']"
        >
          Eligibility Summary Lookup
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="['/eligibilitySummaryHistorical/0']"
          >Eligibility Summary Historical Lookup</a
        >
      </li>
      <li *ngIf="canAccessExperianPayersUpload$ | async" class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="['/experianPayersUpload']"
          >Experian Payers Upload</a
        >
      </li>
      <div>
        <div *ngIf="isAuthenticated$ | async">
          <li class="nav-item">
            <a class="nav-link">
              <button (click)="logout()">Logout</button>
            </a>
          </li>
        </div>
      </div>

      <div *ngIf="(isAuthenticated$ | async) === false">
        <li class="nav-item">
          <a class="nav-link">
            <button (click)="login()">login</button>
          </a>
        </li>
      </div>
    </ul>
  </nav>`,
})
export class AppMenuComponent {
  isAuthenticated$: Observable<boolean>;
  canAccessExperianPayersUpload$: Observable<boolean>;
  public pageTitle = "Rapid Verify 2.0";
  constructor(private authService: AuthService) {
    this.isAuthenticated$ = authService.isAuthenticated$;
    this.canAccessExperianPayersUpload$ =
      authService.canAccessExperianPayersUpload;
  }

  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }

  get email(): string {
    return this.authService.identityClaims
      ? (this.authService.identityClaims as any)["email"]
      : "-";
  }
}
