import {Component, OnInit} from '@angular/core';
import {Reimbursement} from "../../Models/Reimbursement";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {DataService} from "../../shared/data.service";
import {AppError} from "../../Models/AppError";


@Component({
    selector: 'app-reimbursement-history-component',
    templateUrl: './reimbursement-history.component.html',
    styleUrls: ['./reimbursement-history.component.css']
})
export class ReimbursementHistoryComponent implements OnInit {


    constructor(private dataService: DataService, private route: ActivatedRoute) {

    }


    pageTitle = 'Payer Reimbursement History Results';
    errorMessage = '';
    reimbursement!: Reimbursement;

    carrierId = 0;
    npi = '';
    policyNumber = '';


    private dataSub!: Subscription;

    ngOnInit(): void {


        this.carrierId = Number(this.route.snapshot.paramMap.get('carrierId'));
        this.npi = String(this.route.snapshot.paramMap.get('npi'));
        this.policyNumber = String(this.route.snapshot.paramMap.get('policyNumber'));

        this.dataSub = this.dataService.getReimbursementHistory(this.carrierId, this.npi, this.policyNumber)
            .subscribe({
                next: (responseData: Reimbursement | AppError) => {
                    this.reimbursement = <Reimbursement>responseData;
                },
                error: (err: AppError) => {
                    console.error('DataService Observer got an error: ' + JSON.stringify(err));
                    this.errorMessage = err.friendlyMessage;
                },
                complete: () => {
                    console.log('Component Observer got a complete notification');
                }
            });
    }


    ngOnDestroy(): void {

        this.dataSub.unsubscribe();
    }


}
