import {Component, Input, OnInit} from '@angular/core';
import {Entity} from "../../Models/Entity";

@Component({
    selector: 'vp-eligibility-benefits-source',
    templateUrl: './eligibility-benefits-source.component.html',
    styleUrls: ['./eligibility-benefits-source.component.css']
})
export class EligibilityBenefitsSourceComponent implements OnInit {

    constructor() {
    }

    @Input("source")

    get data(): Entity {
        return this._data;
    }

    set data(value: Entity) {
        this._data = value;
    }

    private _data!: Entity


    ngOnInit(): void {
    }

}
