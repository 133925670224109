import { Injectable } from "@angular/core";
import { catchError, map, Observable, shareReplay, switchMap } from "rxjs";
import { Reimbursement } from "../Models/Reimbursement";
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { AppError } from "../Models/AppError";
import { ReimbursementHistory } from "../Models/ReimbursementHistory";
import { AppErrorService } from "./app-error.service";
import { TransactionSummaryEntity } from "../Models/TransactionSummaryEntity";
import { ExperianTransaction } from "../Models/ExperianTransaction";
import {
  EligibilityResponse,
  EligibilityResponseV2,
  IEligibilityResponse,
} from "../Models/EligibilityResponse";
import { HistoricalEligibilitySummary } from "../Models/HistoricalEligibilitySummary";
import { environment } from "../../environments/environment";
import { AuthService } from "../core/auth.service";

@Injectable({
  providedIn: "root",
})

// Data Services for backend API
export class DataService {
  constructor(
    private http: HttpClient,
    private errSvc: AppErrorService,
    private authService: AuthService
  ) {}

  private RapidIvBaseUrl = environment.data_url;

  // fetch a reimbursement history for the passed in data
  public getReimbursementHistory(
    carrierId: number,
    npi: string,
    policyNumber: string
  ): Observable<Reimbursement | AppError> {
    let reimbursementHistoryUrl =
      this.RapidIvBaseUrl + "/reimbursementHistory/v1/get";

    console.log(`getReimbursementHistory -called`);

    //  const requestOptions: HttpHeadersParams = { headers: customHeaders };
    return this.http
      .get<ReimbursementHistory>(reimbursementHistoryUrl, {
        observe: "body",
        params: new HttpParams()
          .set("carrierId", carrierId)
          .set("npi", npi)
          .set("policyNumber", policyNumber),
      })
      .pipe(
        map((data: ReimbursementHistory) => {
          return data.reimbursement;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  // fetch a Transaction Summary for the passed in data
  public getTransactionSummary(
    startDate: Date,
    endDate: Date
  ): Observable<TransactionSummaryEntity[] | AppError> {
    console.log(`getTransactionSummary -called`);
    let url = this.RapidIvBaseUrl + "/reporting/v1/transactionsummary";

    return this.http
      .get<TransactionSummaryEntity[]>(url, {
        observe: "body",
        params: new HttpParams()
          .set("startDate", startDate.toISOString())
          .set("endDate", endDate.toISOString()),
      })
      .pipe(
        map((data: TransactionSummaryEntity[]) => {
          return data;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  // fetch a Transaction for date
  public getExperianTransactionForDate(
    startDate: Date
  ): Observable<ExperianTransaction[] | AppError> {
    console.log(`getExperianTransactionForDate -called`);
    let url = this.RapidIvBaseUrl + "/reporting/v1/experiantransactionsfordate";

    return this.http
      .get<ExperianTransaction[]>(url, {
        observe: "body",
        params: new HttpParams().set("searchDate", startDate.toISOString()),
      })
      .pipe(
        map((data: ExperianTransaction[]) => {
          return data;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  // fetch a Transaction for id
  public getExperianTransactionForId(
    id: string
  ): Observable<ExperianTransaction | AppError> {
    console.log(`getExperianTransactionForId -called`);
    let url = this.RapidIvBaseUrl + "/reporting/v1/experiantransactionforid";

    return this.http
      .get<ExperianTransaction>(url, {
        observe: "body",
        params: new HttpParams().set("Id", id),
      })
      .pipe(
        map((data: ExperianTransaction) => {
          return data;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  public IsV2(source: any) {
    return source.hasOwnProperty("experianResponseText");
  }

  // fetch an eligibility summary for id
  public getEligibilitySummary(id: string): Observable<any | AppError> {
    console.log(`getEligibilitySummary -called`);
    let url =
      this.RapidIvBaseUrl + "/eligibilitysummary/v1/geteligibilitysummary";

    return this.http
      .get<any>(url, {
        observe: "body",
        params: new HttpParams().set("id", id),
      })
      .pipe(
        map((data: IEligibilityResponse) => {
          return data;
        }),

        /*  switchMap(data => {
                if(this.IsV2(data)) {
                    map((data: EligibilityResponseV2) => {
                        return data as EligibilityResponseV2;
                    })
                }
                else {
                    map((data: EligibilityResponse) => {
                        return data as EligibilityResponse;
                    })
                }
                return data;
            }),
          */
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  // fetch a eligibility Historical summary for id
  public getHistoricalEligibilitySummary(
    id: string
  ): Observable<HistoricalEligibilitySummary | AppError> {
    console.log(`getHistoricalEligibilitySummary -called`);
    let url =
      this.RapidIvBaseUrl +
      "/eligibilitysummary/v1/gethistoricaleligibilitysummary";

    return this.http
      .get<HistoricalEligibilitySummary>(url, {
        observe: "body",
        params: new HttpParams().set("id", id),
      })
      .pipe(
        map((data: HistoricalEligibilitySummary) => {
          return data;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }

  // post Experian Payers Excel file
  public postExperianPayersFile(
    formData: FormData
  ): Observable<HttpEvent<string> | AppError> {
    console.log(`postExperianPayersFile -called`);
    let url = this.RapidIvBaseUrl + "/ExperianPayersUpload/v1/excelUpdate";

    console.log("post to API: ", this.authService.userEmail);
    return this.http
      .post<string>(url, formData, {
        reportProgress: true,
        observe: "events",
        headers: { "user-email": this.authService.userEmail },
      })
      .pipe(
        map((data: HttpEvent<string>) => {
          return data;
        }),
        shareReplay(),
        catchError((err) => this.errSvc.handleError(err))
      );
  }
}
