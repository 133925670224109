import {Component, Input, OnInit} from '@angular/core';
import {EligibilityBenefitInformation} from "../../Models/EligibilityBenefitInformation";

@Component({
  selector: 'vp-eligibility-benefit-information',
  templateUrl: './eligibility-benefit-information.component.html',
  styleUrls: ['./eligibility-benefit-information.component.css']
})
export class EligibilityBenefitInformationComponent implements OnInit {

  constructor() { }

  @Input ("eligibilityBenefitInformation") data!: EligibilityBenefitInformation[]

  ngOnInit(): void {
  }

}
