import {Component, Input, OnInit} from '@angular/core';
import {Contact} from "../../Models/Contact";

@Component({
  selector: 'vp-entity-contact',
  templateUrl: './entity-contact.component.html',
  styleUrls: ['./entity-contact.component.css']
})
export class EntityContactComponent implements OnInit {

  constructor() { }

  @Input ("entityContact") data!: Contact

  ngOnInit(): void {
  }

}
