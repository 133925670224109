<div *ngIf="data">
<div class="row">
    <div class="row">
        <div class="col-md-3">Qualifier</div>
        <div class="col-md-6"> {{data.qualifier}}</div>
    </div>

    <div class="row">
        <div class="col-md-3">Date</div>
        <div class="col-md-6"> {{data.date}}</div>
    </div>

    <div class="row">
        <div class="col-md-3">Description</div>
        <div class="col-md-6"> {{data.description}}</div>
    </div>
</div>
</div>