import {Component, Input, OnInit} from '@angular/core';
import {QualifiedAmount} from "../../Models/QualifiedAmount";

@Component({
  selector: 'vp-qualified-amount',
  templateUrl: './qualified-amount.component.html',
  styleUrls: ['./qualified-amount.component.css']
})
export class QualifiedAmountComponent implements OnInit {

  constructor() { }

  @Input("qualifiedAmount") data! :QualifiedAmount;

  ngOnInit(): void {
  }

}
