


<div *ngIf="data"> <!-- start source-->

    <div class="card">
        <div class="card-header">
            Source
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-12"></div>
                <div *ngIf="data.name">
                    <div class="card">
                        <div class="card-header">
                            Name
                        </div>

                        <div class="card-body">
                            <vp-name [entityName]="data.name"></vp-name>


                            <div *ngIf="data.name.type">
                                <div class="card">
                                    <div class="card-header">
                                        Type
                                    </div>
                                    <div class="card-body">
                                        <vp-entity-type [entityType]="data.name.type"></vp-entity-type>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3">Prior Authorization Number</div>
                                <div class="col-md-3"> {{data.name.priorAuthorizationNumber}}</div>
                            </div>

                            <div *ngIf="data.name.identification">
                                <div class="card">
                                    <div class="card-header">
                                        Identification
                                    </div>
                                    <div class="card-body">
                                        <vp-identification
                                                [identification]="data.name.identification"></vp-identification>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div *ngIf="data.address">
                    <div class="card">
                        <div class="card-header">
                            Address
                        </div>
                        <div class="card-body">
                            <vp-postal-address [postalAddress]="data.address"></vp-postal-address>
                        </div>
                    </div>
                </div>

                <div *ngIf="data.contacts?.length">
                    <div class="card">
                        <div class="card-header">
                            Contacts
                        </div>
                        <div class="card-body">
                            <div *ngFor="let k of data.contacts">
                                <vp-entity-contact [entityContact]="k"></vp-entity-contact>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="data.requestValidations?.length">
                    <div class="card"> <!-- start requestValidation-->
                        <div class="card-header">
                            Validation
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12"></div>

                                <div *ngFor="let i of data.requestValidations">
                                    <div class="row">
                                        <vp-request-validation [requestValidation]="i"></vp-request-validation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div> <!-- end source-->
