<div *ngIf="data">

<div class="row">
    <div class="col-md-3">Qualifier</div>
    <div class="col-md-3">{{ data.qualifier}}</div>
</div>

<div class="row">
    <div class="col-md-3">Procedure Code</div>
    <div class="col-md-3">{{ data.procedureCode}}</div>
</div>

<div class="row">
    <div class="col-md-3">Procedure End Code</div>
    <div class="col-md-3">{{ data.procedureEndCode}}</div>
</div>
<div class="row">
    <div class="col-md-3">Description</div>
    <div class="col-md-3">{{ data.description}}</div>
</div>

<div class="row">
    <div class="col-md-3">Modifier 1</div>
    <div class="col-md-3">{{ data.modifier1}}</div>
</div>

<div class="row">
    <div class="col-md-3">Modifier 2</div>
    <div class="col-md-3">{{ data.modifier1}}</div>
</div>
<div class="row">
    <div class="col-md-3">Modifier 3</div>
    <div class="col-md-3">{{ data.modifier1}}</div>
</div>
<div class="row">
    <div class="col-md-3">Modifier 4</div>
    <div class="col-md-3">{{ data.modifier1}}</div>
</div>

</div>