<div *ngIf="data">

<div class="row">
    <div class="col-md-3">Valid Request</div>
    <div class="col-md-3"> {{data.validRequest}}</div>
</div>


<div *ngIf="data.rejectReason">
    <div class="row">
        <vp-experian-lookup [title]="'Rejection Reason'" [experianLookup]="data.rejectReason"></vp-experian-lookup>
    </div>
</div>


<div *ngIf="data.follupAction">
    <div class="row">
        <vp-experian-lookup [title]="'Followup Action'" [experianLookup]="data.follupAction"></vp-experian-lookup>
    </div>
</div>
</div>