import {Component, OnInit} from '@angular/core';

import {DataService} from "../../shared/data.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {AppError} from "../../Models/AppError";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ViewChild} from "@angular/core";
import {HttpUrlEncodingCodec} from "@angular/common/http";
import {ExperianTransactionSummery} from "../../Models/ExperianTransactionSummery";

@Component({
    selector: 'app-daily-transactions-summary',
    templateUrl: './daily-transactions-summary.component.html',
    styleUrls: ['./daily-transactions-summary.component.css']
})

export class DailyTransactionsSummaryComponent implements OnInit {


    title = "Daily Transactions Summary";
    errorMessage = '';
    searchDate!: Date;

    displayColumn: string[] = ['id', 'prospectId', 'carrierId', 'payer', 'state', 'npi', 'isError', 'isEligible','response'];
    datasource!: MatTableDataSource<ExperianTransactionSummery>
    data: ExperianTransactionSummery[] = [];
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    filter!: string|null;

    private dataSub!: Subscription;
    HttpUrlEncodingCodec = new HttpUrlEncodingCodec();

    constructor(private dataService: DataService, private route: ActivatedRoute) {
    }



   applyFilter(event: Event) {
        if(event.target) {
            const filterValue = (event.target as HTMLInputElement)?.value;
            this.datasource.filter = filterValue?.trim()?.toLowerCase();
            if (this.datasource.paginator) {
                this.datasource.paginator.firstPage()
            }
            console.log(filterValue);
        }
    }



    ngOnInit(): void {



        let str = this.route.snapshot.paramMap.get('transactionDate');
        this.filter = this.route.snapshot.paramMap.get('filter')

        if (str != null) {
            this.searchDate = new Date(new Date(str));
        }

        this.dataSub = this.dataService.getExperianTransactionForDate(this.searchDate)
            .subscribe({
                next: (responseData: ExperianTransactionSummery[] | AppError) => {

                    let fulldata = <ExperianTransactionSummery[]>responseData;
                    if (this.filter === 'errors'){
                        fulldata =   fulldata.filter((obj) => {
                            return obj.isError;
                        });
                    }
                    if (this.filter === 'eligible'){
                        fulldata = fulldata.filter((obj) => {
                            return obj.isEligible && !obj.isError;
                        });
                    }
                    if (this.filter === 'notEligible'){
                        fulldata = fulldata.filter((obj) => {
                            return !obj.isEligible && !obj.isError;
                        });
                    }

                    this.data = <ExperianTransactionSummery[]>fulldata;
                    this.datasource = new MatTableDataSource<ExperianTransactionSummery>(this.data);
                    this.datasource.filterPredicate = function customFilter(data: ExperianTransactionSummery , filter:string ): boolean {
                        return (
                            data.id.toLowerCase().includes(filter) ||
                            data.prospectId.toLowerCase().includes(filter) ||
                            data.carrierId.toLowerCase().includes(filter) ||
                            data.payer.toLowerCase().includes(filter)||
                            data.state.toLowerCase().includes(filter)||
                            data.npi.toLowerCase().includes(filter) ||
                            String(data.isError).toLowerCase().includes(filter)||
                            String(data.isEligible).toLowerCase().includes(filter)
                        );
                    }
                    this.datasource.paginator = this.paginator;
                    this.datasource.sort = this.sort;

                },
                error: (err: AppError) => {
                    console.error('DataService Observer got an error: ' + JSON.stringify(err));
                    this.errorMessage = err.friendlyMessage;
                },
                complete: () => {
                    console.log('Component Observer got a complete notification');
                }
            })
    };

    ngOnDestroy(): void {
        this.dataSub.unsubscribe();
    }



}
