<div *ngIf="data">
    <div class="card"> <!-- start Eligibility Benefit Response-->
        <div class="card-header">
            Eligibility Benefit Response
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-3">Transaction Number</div>
                <div class="col-md-6">{{data.transactionControlNumber}}</div>
            </div>

            <div *ngIf="data.subscriber">
                <vp-benefit-member [benefitMember]="data.subscriber"
                                   [memberTypeTitle]="'Subscriber'"></vp-benefit-member>
            </div>

            <div *ngIf="data.dependent">
                <vp-benefit-member [benefitMember]="data.dependent" [memberTypeTitle]="'Dependent'"></vp-benefit-member>
            </div>

            <div *ngIf="data.benefitInfos">
                <vp-eligibility-benefit-information
                        [eligibilityBenefitInformation]="data.benefitInfos"></vp-eligibility-benefit-information>
            </div>


            <div *ngIf="data.source">
                <vp-eligibility-benefits-source [source]="data.source"></vp-eligibility-benefits-source>
            </div>

            <div *ngIf="data.receiver">
                <vp-eligibility-benefits-receiver [receiver]="data.receiver"></vp-eligibility-benefits-receiver>
            </div>


        </div>  <!-- end Eligibility Benefit Response-->

    </div>
</div>